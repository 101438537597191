import { createRef, useEffect, useState } from "react";
import { useSVG } from "../SVGContext";
import { iconIdToIconSrc } from "../library/ImagesPanel";

export function OptionsView({ options, item, add }) {
  const gap = 20,
    padding = 20;
  const cols = 4;
  const w = item.width * cols + gap * cols + 2 * padding;
  const rows = Math.ceil(options.length / cols);
  const h = item.height * rows + gap * rows + 2 * padding;

  return (
    <foreignObject x={item.x} y={item.y + item.height + 20} width={w} height={h}>
      <div
        xmlns="http://www.w3.org/1999/xhtml"
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: gap,
          justifyContent: "start",
          padding: padding,
          background: "white",
          border: "1px solid black",
          borderRadius: 10,
        }}
      >
        {options.map((option, index) => (
          <div
            className="svg-option-container"
            key={index}
            style={{
              width: item.width,
              height: item.height,
            }}
            onMouseDown={(e) => {
              e.stopPropagation();
              e.preventDefault();
              add(option);
            }}
          >
            <img
              src={option.src}
              style={{
                width: item.width,
                height: item.height,
              }}
            />
          </div>
        ))}
      </div>
    </foreignObject>
  );
}

export function VectorView({ item, overlay }) {
  const { addVector, selectedIds } = useSVG();
  const [showOptions, setShowOptions] = useState(false);

  const optionsOptions =
    item.icon_options?.length > 0
      ? {
          showOptions,
          options: item.icon_options.map((id) => ({ src: id.startsWith("https:") ? id : iconIdToIconSrc(id) })),
          item,
          overlay,
          add: (option) => {
            addVector(option, item.id);
          },
        }
      : {};

  return (
    <>
      {showOptions && overlay && selectedIds.includes(item.id) && item.icon_options?.length > 0 && <OptionsView {...optionsOptions} />}
      <foreignObject x={item.x} y={item.y} width={item.width} height={item.height}>
        {/* wrapping so that hovering over it highlights it more easily */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          id={item.id}
          viewBox={item.viewbox}
          // x={item.x}
          // y={item.y}
          width={item.width}
          height={item.height}
          fill={item.fill}
          stroke={item.stroke}
          strokeWidth={item.strokeWidth}
          dangerouslySetInnerHTML={{ __html: item.svg }}
          onDoubleClick={(e) => {
            if (overlay) {
              setShowOptions(!showOptions);
            }
          }}
          onBlur={() => {
            setShowOptions(false);
          }}
        />
      </foreignObject>{" "}
    </>
  );
}
