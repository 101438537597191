// @ts-nocheck
import { Box, IconButton } from "@mui/material";
import { useSVG } from "../SVGContext";
import { MpImageView } from "./MpImageView";
import { ShapeView } from "./ShapeView";
import { TextView } from "./TextView";
import { RotateBtn } from "./RotateBtn";
import { getTransformStr } from "../utils/transformUtils";
import { ClipPath, Shape } from "./Shape";
import { EditBtn } from "./EditBtn";
import { SteppedConnector } from "./SteppedConnector";
import SVGDef from "./SvgDefView";
import { VectorView } from "./VectorView";

const SVGItemView = ({ item, overlay }) => {
  if (!item) return null;
  const { svgRef, croppingItemId, selectedIds } = useSVG();

  if (overlay) {
    if (!selectedIds.includes(item.id)) return;
    if (croppingItemId && item.type !== "mpimage") return;
  }

  if (!item.mpTransforms) return <SVGItemViewWithoutGroup item={item} overlay={overlay} />;
  const str = getTransformStr(svgRef, item);
  return (
    <g transform={str}>
      <SVGItemViewWithoutGroup item={item} overlay={overlay} />
      <Commons item={item} overlay={overlay} />
    </g>
  );
};

const Commons = ({ item, overlay }) => {
  if (!overlay) return null;
  return <>{/* <RotateBtn item={item} /> */}</>;
};

const SVGItemViewWithoutGroup = ({ item, overlay }) => {
  const { croppingItemId } = useSVG();

  if (!item || item.hidden) return null;
  if (overlay && croppingItemId && item.type !== "mpimage") return;

  const tag = item.spec && (
    <>
      <g transform={`translate(${item.x}, ${item.y})`}>
        <rect x={0} y={-20} width={item.spec?.type.length * 10} height={24} fill={item.spec?.color} rx={5} ry={5} />
        <text height={30} fill="#000" fontSize={24}>
          {item.spec?.type}
        </text>
      </g>
    </>
  );
  const defs = <defs>{item.defs?.map((def, i) => <SVGDef key={i} def={def} />)}</defs>;

  const transforms = gatherTransforms(item);
  if (transforms) {
    // <svg> doesn't rotate itself when I pass this as a transform to it, so I'm wrapping it & every other type of item in a <g> tag
    return (
      <g transform={transforms}>
        <SVGItemViewWithoutGroup item={{ ...item, ignoreTransforms: true }} />
      </g>
    );
  }

  function getSimpleView() {
    if (!overlay) {
      // don't do ...item in ShapeView and for path. render only svg attributes
      if (["rectangle", "circle", "ellipse", "triangle", "star", "pentagon", "hexagon", "semi-circle", "quarter-circle", "straight"].includes(item.type)) return <ShapeView item={item} overlay={overlay} />;

      if (item.type == "path") return <path {...item} overlay={overlay} />;
      if (item.type == "shape") return <Shape item={item} overlay={overlay} />;
      if (item.type == "style") return <style>{item.content}</style>;
      if (item.type == "stepped") return <SteppedConnector item={item} overlay={overlay} />;
    }
    if (item.type == "mpimage") return <MpImageView item={item} overlay={overlay} />;
    if (item.proseMirrorData) return <TextView item={item} item={item} overlay={overlay} />;
    if (item.type == "vector" || item.type == "icon") return <VectorView item={item} overlay={overlay} />;
  }

  return (
    <g>
      {defs}
      {getSimpleView()}
      {tag}
    </g>
  );
};

export default SVGItemView;

export function gatherTransforms(item) {
  if (item.ignoreTransforms) return null;
  const transforms = [];
  if (item.rotateDeg) transforms.push(`rotate(${item.rotateDeg} ${item.x + item.width / 2} ${item.y + item.height / 2})`);

  // note: this is for editorg. venn doesn't have this, I think. Idk how they do scaling. we just modify width/height etc
  if (item.scaleX || item.scaleY) {
    transforms.push(`scale(${item.scaleX || 1} ${item.scaleY || 1})`);
  }

  return transforms.join(" ");
}
